<template>
  <b-card
    :id="`product-${product.id}`"
    class="rounded-5 h-100 p-1 mb-0"
    no-body
    @click="add"
  >
    <b-card-title class="m-0 p-0">
      <b-row>
        <b-col
          sm="auto"
          class="pl-1 pr-0"
        >
          <!-- <b-img
            v-if="product.logo"
            width="60px"
            rounded
            :src="`${product.logo}`"
          /> -->
        </b-col>
        <b-col class="d-flex flex-column justify-content-between">
          <h5 class="mb-0">
            {{ product.name }}
          </h5>
          <div
            v-if="product.category"
            class="d-flex justify-content-between align-items-center"
          >
            <div>
              <b-badge
                pill
                variant="primary"
                class="text-small"
              >
                {{ product.category.name }}
              </b-badge>
            </div>
            <span class="text-muted text-small">
              {{ product.sku }} -
              <span class="font-weight-bolder text-dark">
                {{ product.brand || 'N/A' }}
              </span>
            </span>
          </div>
        </b-col>
      </b-row>
    </b-card-title>
    <b-row>
      <b-col class="d-flex justify-content-between align-items-center mt-1">
        <b-form-group
          v-if="product.isMaterial"
          class="mb-0"
          :label="$t('topQuantity')"
          label-for="input-sm"
        >
          <b-input-group size="sm">
            <b-form-input
              :value="product.top_quantity"
              size="sm"
              type="number"
              min="1"
              class="qty-field-2"
              autocomplete="off"
              @input="
                changeField({
                  item: product,
                  field: 'top_quantity',
                  value: $event,
                })
              "
            />
            <b-input-group-append is-text>
              {{ $tc(unitsFilter(product.unit_type)) }}
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group
          class="mb-0"
          :label="$t('topAmount')"
          label-for="input-sm"
        >
          <b-form-input
            :value="product.top_amount"
            size="sm"
            type="number"
            min="1"
            class="qty-field"
            autocomplete="off"
            @input="
              changeField({ item: product, field: 'top_amount', value: $event })
            "
          />
        </b-form-group>
        <b-button
          size="sm"
          variant="outline-danger"
          class="d-flex custome-button mt-1"
          @click="remove(index)"
        >
          <feather-icon
            icon="TrashIcon"
            class=""
          />
        </b-button>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col class="d-flex justify-content-between align-items-center">
        <b-form-group
          class="mb-0"
          :label="$t('pruchasedQuantity')"
          label-for="input-sm"
        >
          <b-form-input
            :value="product.purchased_quantity"
            size="sm"
            type="number"
            min="1"
            class="qty-field"
            autocomplete="off"
            @input="changeField({ item: product, field:'purchased_quantity', value: $event })"
          />
        </b-form-group>
        <b-form-group
          class="mb-0"
          :label="$t('pruchasedAmount')"
          label-for="input-sm"
        >
          <b-form-input
            :value="product.purchased_amount"
            size="sm"
            type="number"
            min="1"
            class="qty-field"
            autocomplete="off"
            @input="changeField({ item: product, field:'purchased_amount', value: $event })"
          />
        </b-form-group>
      </b-col>
    </b-row> -->
  </b-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {},

  props: {
    product: {
      type: Object,
      default: () => ({
        name: '',
        category: '',
        categories_ids: [],
        img: '',
        price: 0,
        cost: 0,
        variant: '',
      }),
    },
    index: {
      type: Number,
      default() {
        return null
      },
    },
    cart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isModalVisible: false,
      selectedProduct: {},
    }
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('pos', ['posItems']),
    ...mapGetters('app', ['unitsFilter']),
  },
  mounted() {},
  methods: {
    ...mapMutations('pos', {
      addProduct: 'ADD_ITEM_TO_POS',
      removeProduct: 'REMOVE_ITEM_FROM_POS',
      changeField: 'CHANGE_ITEM_FIELD',
    }),

    add() {
      if (
        !this.posItems.find(item => item.id === this.product.id)
        && !this.cart
      ) {
        this.addProduct({ ...this.product })
      }
    },
    remove(index) {
      this.removeProduct({ index })
    },
  },
}
</script>

<style lang="scss" scoped>
.text-small {
  font-size: 11px;
}
.custome-button {
  padding-left: 2px;
  padding-right: 2px;

  .custom-margin {
    margin-right: 3px;
  }
}

.qty-field {
  max-width: 100px;
}
.qty-field-2 {
  max-width: 60px;
}
</style>
