<template>
  <div>
    <b-row>
      <b-col sm="12">
        <b-card class="mb-0">
          <h2 class="d-flex justify-content-between">
            {{ $t('products') }}
            <span class="text-muted h5">{{ cartItemsLength }}</span>
          </h2>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    cartItemsLength: {
      type: Number,
      default() {
        return 0
      },
    },
  },

  data() {
    return {}
  },

  computed: {},

  methods: {},
}
</script>

<style lang="scss" scoped>
</style>
