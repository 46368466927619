<template>
  <div>
    <b-row>
      <b-col
        v-for="(item, n) in cartItems"
        :key="`cartItem-${item.id}`"
        class="mt-1"
        sm="12"
      >
        <products-card-expense
          v-if="$route.name === 'addGroupedExpenses'"
          :product="item"
          :index="n"
          :cart="true"
        />
        <products-card
          v-else
          :product="item"
          :index="n"
          :cart="true"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ProductsCard from '@/components/pos/ProductsCard.vue'
import ProductsCardExpense from '@/components/pos/ProductsCardExpense.vue'

export default {
  components: {
    ProductsCard,
    ProductsCardExpense,
  },

  props: {
    cartItems: {
      type: Array,
      default() {
        return []
      },
    },
  },

  data() {
    return {}
  },

  computed: {},

  methods: {},
}
</script>

<style lang="scss" scoped></style>
