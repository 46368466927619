<template>
  <b-card
    class="mb-05"
    :class="{
      'cursor-pointer hover-bg': validRoute,
      'hover-candy': currentUser.role_name !== 'admin',
    }"
    no-body
  >
    <b-dropdown
      v-if="currentUser.role_name === 'admin'"
      id="dropdown-1"
      toggle-class="my-custom-toggle"
      size="sm"
      right
      no-caret
      variant="outline-none"
      data-bs-toggle="dropdown"
    >
      <template #button-content>
        <feather-icon
          icon="MoreVerticalIcon"
          size="18"
          color="#000"
        />
      </template>
      <b-dropdown-item
        v-if="$route.name === 'productsCatalogs'"
        :to="{ name: 'editProduct', params: { productId: product.id } }"
      >{{ $t('edit') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="product.active_status"
        v-b-modal="`confirmDelete-${product.id}`"
      >
        <span class="text-danger">
          {{ $t('delete') }}
        </span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="!product.active_status"
        v-b-modal="`confirmReactivate-${product.id}`"
      >
        <span class="text-success">
          {{ $t('reactivate') }}
        </span>
      </b-dropdown-item>
    </b-dropdown>
    <b-row>
      <b-col class="d-flex">
        <div class="pr-1">
          <b-img
            v-if="false"
            style="height:90px"
            width="90px"
            :src="`${product.logo}`"
          />
        </div>
        <div>
          <h4 class="mb-0 mt-1">
            {{ product.name }}
          </h4>
          <div class="d-flex flex-column">
            <span
              class="text-muted text-small mb-0"
            >{{ product.sku }} -
              <span class="font-weight-bolder text-dark">
                {{ product.brand || 'N/A' }}
              </span>
            </span>
            <div>
              <b-badge
                pill
                variant="primary"
                class="text-small mb-1"
              >
                {{ product.category.name || '' }}
              </b-badge>
            </div>
          </div>
        </div>
      </b-col>
      <b-col class="d-none d-md-block">
        <div class="px-2">
          <h5 class="mt-1 text-small">
            {{ $t('description') }}
          </h5>
          <div class="text-justify text-muted">
            {{ product.description }}
          </div>
        </div>
      </b-col>
      <b-col
        v-if="validRoute"
        xs="12"
        sm="6"
        md="4"
        class="d-flex justify-content-end align-items-center mb-1 my-sm-0 mr-1 mx-sm-0"
      >
        <b-form-group class="m-0 mr-1">
          <b-input-group size="sm">
            <b-form-input
              v-model="quantity"
              type="number"
              min="1"
              class="qty-field"
              :placeholder="$t('quantity')"
              autocomplete="off"
              :state="quantityValid"
            />
            <b-input-group-append is-text>
              {{ $tc(unitsFilter(product.unit_type)) }}
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-button
          size="sm"
          variant="outline-primary"
          class="d-flex custom-button"
          @click="add()"
        >
          <feather-icon
            icon="PlusIcon"
            size="14"
          />
        </b-button>
      </b-col>
      <b-col cols="auto" />
    </b-row>
    <confirmation-modal
      :id="`confirmDelete-${product.id}`"
      :variant="'danger'"
      @confirm="deleteProduct()"
    >
      <template v-slot:content>
        {{ $t('deleteProductConfirmation') }}
      </template>
    </confirmation-modal>
    <confirmation-modal
      :id="`confirmReactivate-${product.id}`"
      :variant="'success'"
      @confirm="reactivateProduct()"
    >
      <template v-slot:content>
        {{ $t('reactivateProductConfirmation') }}
      </template>
    </confirmation-modal>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    ConfirmationModal,
  },
  props: {
    product: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  data() {
    return {
      quantity: null,
      quantityValid: null,
    }
  },

  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('app', ['unitsFilter']),
    ...mapGetters('groupedExpenses', ['expenseType']),
    validRoute() {
      return (
        this.$route.name === 'registerSupplierProduct'
        || this.$route.name === 'addProductsToQuote'
        || this.$route.name === 'addGroupedExpenses'
      )
    },
    breakpoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isSmallScreen() {
      switch (this.$store.getters['app/currentBreakPoint']) {
        case 'md':
        case 'sm':
        case 'xs':
          return true
        default:
          return false
      }
    },
  },
  methods: {
    ...mapMutations('pos', {
      addItem: 'ADD_ITEM_TO_POS',
    }),
    ...mapActions('products', ['deactivateProduct', 'fetchProducts']),

    add() {
      if (!this.validateDecimals()) {
        return
      }
      if (this.$route.name === 'addGroupedExpenses') {
        this.addItem({
          ...this.product,
          expense_type_catalog_id: this.expenseType,
          isMaterial: true,
        })
      } else {
        this.addItem({ ...this.product, quantity: this.quantity })
      }
    },

    deleteProduct() {
      this.deactivateProduct(this.product.id).then(() => {
        this.fetchProducts({
          by_active_status: true,
          meta: {
            pagination: {
              per_page: 100,
            },
          },
        })
      })
    },
    reactivateProduct() {
      this.deactivateProduct(this.product.id).then(() => {
        this.fetchProducts({
          by_active_status: false,
          meta: {
            pagination: {
              per_page: 100,
            },
          },
        })
      })
    },
    validateDecimals() {
      if (this.quantity.includes('.') && this.quantity.split('.')[1].length > 2) {
        this.quantityValid = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Solo se permiten 2 decimales',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        return false
      }
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-best-price {
  background-color: #77c77b;
}
.mb-05 {
  margin-bottom: 0.5rem;
}
.text-small {
  font-size: 10px;
}
.hover-bg:hover {
  background-color: rgba(52, 6, 104, 0.12);
}
.hover-candy:hover {
  transform: translateY(-3px);
}
.custom-button {
  padding: 4px 4px;
}
#dropdown-1 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
}
</style>
