<template>
  <div class="custom-container">
    <b-row
      class="justify-content-between px-1 pb-1 pt-0 pt-sm-1 align-items-center"
    >
      <h1 class="font-weight-bolder mb-0">
        {{ $t('add', { type: $t('products') }) }}
      </h1>
      <div
        v-if="$route.name === 'addProductsToQuote'"
        class="d-flex align-items-center"
      >
        <div class="mr-1 d-flex flex-wrap">
          <div class="d-flex justify-content-between mb-1">
            <div>
              <feather-icon
                :id="`tooltip-limit-suppliers`"
                icon="HelpCircleIcon"
                size="20"
                color="#340668"
                class="mr-50"
              />
              <b-tooltip
                :target="`tooltip-limit-suppliers`"
                triggers="hover"
                boundary-padding="10"
              >
                <span>
                  {{ $t('limitSuppliersInfo') }}
                </span>
              </b-tooltip>
              <span class="ml-0 mr-1">
                {{ $t('limitSuppliers') }}
              </span>
            </div>
            <b-form-checkbox
              v-model="quote.limit_suppliers"
              class=""
              name="check-button"
              switch
              @change="setLimitSuppliersValue"
            />
          </div>

          <div class="d-flex justify-content-between">
            <div>
              <feather-icon
                :id="`tooltip-express`"
                icon="HelpCircleIcon"
                size="20"
                color="#340668"
                class="mr-50"
              />
              <b-tooltip
                :target="`tooltip-express`"
                triggers="hover"
                boundary-padding="10"
              >
                <span>
                  {{ $t('expressQuoteDescription') }}
                </span>
              </b-tooltip>
              <span class="ml-0 mr-1">
                {{ $t('expressdelivery') }}
              </span>
            </div>
            <b-form-checkbox
              v-model="quote.is_express"
              class=""
              name="check-button"
              switch
              @change="setExpressValue"
            />
          </div>
        </div>
        <b-button
          class="mr-1"
          :size="isSmallScreen ? 'sm' : 'md'"
          variant="primary"
          @click="saveQuoteProducts()"
        >
          {{ $t('save') }}
        </b-button>
        <b-overlay :show="publishLoading">
          <b-button
            v-b-modal.confirmModal
            :size="isSmallScreen ? 'sm' : 'md'"
            variant="outline-primary"
          >
            {{ $t('requestQuote') }}
          </b-button>
        </b-overlay>
      </div>
      <div v-else-if="$route.name === 'registerSupplierProduct'">
        <b-button
          :size="isSmallScreen ? 'sm' : 'md'"
          variant="primary"
          @click="saveProducts()"
        >
          {{ $t('save') }}
        </b-button>
      </div>
      <div
        v-else-if="$route.name === 'addGroupedExpenses'"
        class="d-flex"
      >
        <b-dropdown
          id="dropdown-1"
          variant="outline-primary"
          :size="isSmallScreen ? 'sm' : 'md'"
          :text="$t('add', { type: $t('service') })"
          class="mr-2"
        >
          <div class="scrollable">
            <b-dropdown-item
              v-for="expense in expenseTypeCatalogs.filter(
                x => x.name != 'Material',
              )"
              :key="expense.id"
              :disabled="isIncomeAlreadyAdded(expense)"
              @click="addGroupedExpense(expense)"
            >
              {{ expense.name }}
            </b-dropdown-item>
          </div>
        </b-dropdown>
        <!-- <b-form-select
          v-model="expenseType"
          disabled
          class="mr-2 border-primary"
          :options="expensesTypes"
        /> -->
        <b-button
          :size="isSmallScreen ? 'sm' : 'md'"
          variant="primary"
          @click="saveExpenses()"
        >
          {{ $t('save') }}
        </b-button>
      </div>
      <b-button
        v-if="isSmallScreen"
        variant="primary"
        class="position-relative"
        size="sm"
        @click="openModalCart"
      >
        <span
          v-if="posItems.length > 0"
          class="notification-number"
        >
          {{ posItems.length }}
        </span>
        <feather-icon
          icon="ShoppingCartIcon"
          :size="isSmallScreen ? '16' : '17'"
          color="#fff"
        />
      </b-button>
    </b-row>
    <b-row class="mb-1">
      <b-col sm="12" class="mb-1">
        <div>
          <b-form-input
            v-model="searchQuery"
            class="w-100"
            autocomplete="off"
            type="text"
          />
        </div>
      </b-col>
      <b-col
        v-if="true"
        sm="12"
        class="mb-1 d-flex"
      >
        <b-dropdown
          class="mr-2"
          size="md"
          variant="outline-primary"
          left
          :text="selectedCategory === null ? $t('all') : selectedCategory"
        >
          <div class="scrollable">
            <b-dropdown-item
              class="mr-1"
              variant="primary"
              @click="selectedCategory = null"
            >
              {{ $t('all') }}
            </b-dropdown-item>
            <b-dropdown-item
              v-for="category in categories"
              :key="`cat-${category.id}`"
              class="mr-1"
              variant="primary"
              @click="selectedCategory = category.name"
            >
              {{ category.name }}
            </b-dropdown-item>
          </div>
        </b-dropdown>

        <!-- <b-form-input
          v-model="searchQuery"
          class="w-100"
          autocomplete="off"
          type="text"
        /> -->
      </b-col>
      <b-col v-else>
        <b-overlay
          :show="categoriesLoading"
          opacity=".9"
        >
          <b-form-input
            v-model="searchQuery"
            class="search-input w-100"
            autocomplete="off"
            type="text"
          />
          <div class="mt-50">
            <b-badge
              pill
              size="sm"
              class="mr-50 mb-50 cursor-pointer"
              :variant="selectedCategory === null ? 'warning' : 'primary'"
              @click="selectedCategory = null"
            >
              Todas
            </b-badge>
            <b-badge
              v-for="category in categories"
              :key="`cat-${category.id}`"
              pill
              size="sm"
              class="mr-50 mb-50 cursor-pointer"
              :variant="
                selectedCategory === category.name ? 'warning' : 'primary'
              "
              @click="selectedCategory = category.name"
            >
              {{ category.name }}
            </b-badge>
          </div>
        </b-overlay>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        sm="12"
        lg="9"
        class="products overflow-auto"
        @scroll="handleScroll"
      >
        <b-overlay
          :show="loading"
          opacity="1"
        >
          <div
            v-for="product in productsNotInCart"
            :key="`prod-${product.id}`"
            @click="add(product)"
          >
            <product-row :product="product" />
          </div>
          <b-overlay :show="productsLoading">
            <b-card
              v-if="!allPagesLoaded"
              style="height: 90px;"
            />
          </b-overlay>
        </b-overlay>
      </b-col>
      <!-- Only show cart on the side on big enough screens -->
      <b-col
        v-if="isSmallScreen === false"
        class="pos overflow-auto"
        sm="12"
        lg="3"
      >
        <b-overlay
          :show="loading"
          opacity="1"
        >
          <total-cart-items :cart-items-length="posItems.length" />
          <cart
            ref="cart"
            :cart-items="posItems"
          />
        </b-overlay>
      </b-col>
    </b-row>
    <b-modal
      v-if="isSmallScreen === true"
      id="modal-mobile-cart"
      size="xl"
      title=""
      class="modal-h-100"
    >
      <b-row>
        <b-col
          class="pos overflow-auto"
          sm="12"
          lg="3"
        >
          <b-overlay
            :show="loading"
            opacity="1"
          >
            <total-cart-items :cart-items-length="posItems.length" />
            <cart
              ref="cart"
              :cart-items="posItems"
            />
          </b-overlay>
        </b-col>
      </b-row>
    </b-modal>
    <confirmation-modal @confirm="saveAndPublish">
      <template v-slot:content>
        {{ $t('publishQuoteConfirmation') }}
      </template>
    </confirmation-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import _ from 'underscore'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'
import Cart from '@/components/pos/Cart.vue'
import TotalCartItems from '@/components/pos/TotalCartItems.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import ProductRow from '@/components/products/ProductRow.vue'

export default {
  components: {
    Cart,
    TotalCartItems,
    ConfirmationModal,
    ProductRow,
  },

  data() {
    return {
      stockable: {
        stockable_type: this.$route.params.type,
        stockable_id: this.$route.params.id,
        stockable_records: [],
      },
      quote: {
        quote_items_attributes: [],
      },
      cardMode: true,
      selectedCategory: null,
      searchQuery: null,
      loading: false,
      productsLoading: false,
      categoriesLoading: false,
      expensesTypes: [],
      publishLoading: false,
      page: 1,
    }
  },

  computed: {
    ...mapGetters('products', ['products', 'productsPagination']),
    ...mapGetters('categories', ['categories']),
    ...mapGetters('pos', ['posItems']),
    ...mapGetters('groupedExpenses', [
      'groupedExpenses',
      'expenseTypeCatalogs',
      'expenseType',
    ]),

    productsNotInCart() {
      return this.products.filter(product => {
        // Reverse search for expenses
        if (this.$route.name === 'addGroupedExpenses') {
          if (this.posItems.find(item => item.id === product.id)) {
            return false
          }
          if (this.groupedExpenses.find(item => item.sku === product.sku)) {
            return false
          }
          return true
        }
        // Normal search
        if (!this.posItems.find(item => item.id === product.id)) {
          return true
        }
        return false
      })
    },
    breakpoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isSmallScreen() {
      switch (this.$store.getters['app/currentBreakPoint']) {
        case 'md':
        case 'sm':
        case 'xs':
          return true
        default:
          return false
      }
    },
    allPagesLoaded() {
      if (this.productsPagination.page < this.productsPagination.total_pages) {
        return false
      }
      return true
    },
  },

  watch: {
    searchQuery: _.debounce(function search(query) {
      // this.loading = true
      if (query === '') {
        this.fetchProducts({
          by_active_status: true,
          meta: {
            pagination: this.productsPagination,
          },
        })
      } else {
        this.filterBy(query)
      }
      // this.loading = false
    }, 400),

    selectedCategory() {
      this.page = 1
      this.filterBy(this.selectedCategory)
    },
  },

  created() {
    this.categoriesLoading = true
    this.fetchCategories({ by_active_status: true }).then(() => {
      this.categoriesLoading = false
    })
    this.fetchData()
  },

  destroyed() {
    this.clearPos()
    this.clearProducts()
  },

  methods: {
    ...mapMutations('groupedExpenses', {
      setExpenseType: 'SET_EXPENSE_TYPE',
    }),
    ...mapMutations('products', {
      changeProductsPagination: 'CHANGE_PRODUCTS_PAGINATION',
      setProducts: 'SET_PRODUCTS',
    }),
    ...mapActions('products', [
      'fetchProducts',
      'addStockableProduct',
      'getStockableProducts',
    ]),
    ...mapActions('groupedExpenses', [
      'fetchGroupedExpenses',
      'addGroupedExpenses',
      'fetchExpensesTypeCatalogs',
    ]),
    ...mapActions('categories', ['fetchCategories']),
    ...mapActions('quotes', [
      'editQuote',
      'fetchQuote',
      'publishQuote',
      'setQuoteBidding',
    ]),
    ...mapMutations('pos', {
      addItem: 'ADD_ITEM_TO_POS',
      clearPos: 'CLEAR_POS_ITEMS',
    }),
    ...mapMutations('products', {
      clearProducts: 'CLEAR_PRODUCTS',
    }),

    scrollToCart() {
      const el = this.$refs.cart.$el
      el.scrollIntoView({ behavior: 'smooth' })
    },

    openModalCart() {
      this.$bvModal.show('modal-mobile-cart')
    },

    fetchData() {
      this.loading = true
      // cotizacion
      if (this.$route.name === 'addProductsToQuote') {
        Promise.all([
          this.fetchProducts({
            by_active_status: true,
            meta: {
              pagination: this.productsPagination,
            },
          }),
          this.fetchQuote(this.$route.params.quoteId),
        ])
          .then(response => {
            // Array Destructuring, DOCS: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment
            const [, quote] = response
            this.quote = quote
            this.quote.quote_items_attributes.forEach(quoteItem => {
              const product = quoteItem.product_catalog
              // Deprectaed since now products are paginated
              // const quoteProduct = this.products.find(
              //   product => product.id === quoteItem.product_catalog_id,
              // )
              if (product) {
                product.quantity = quoteItem.quantity
                this.addItem(product)
              }
            })
          })
          .finally(() => {
            this.loading = false
          })
      } else if (this.$route.name === 'registerSupplierProduct') {
        // agregar productos a sucursal o proveedor
        let type = ''
        if (this.$route.params.type === 'proveedor') {
          type = 'Supplier'
        }
        if (this.$route.params.type === 'sucursal') {
          type = 'SupplierBranch'
        }
        Promise.all([
          this.fetchProducts({
            by_active_status: true,
            meta: {
              pagination: this.productsPagination,
            },
          }),
          this.getStockableProducts({
            stockable: {
              id: this.$route.params.id,
              type,
            },
            params: {
              meta: {
                pagination: {
                  all: true,
                },
              },
            },
          }),
        ])
          .then(response => {
            const [, stockableProducts] = response
            stockableProducts.data.forEach(stock => {
              const product = stock.product_catalog
              if (product) {
                product.quantity = stock.price_list
                this.addItem(product)
              }
            })
          })
          .finally(() => {
            this.loading = false
          })
      } else if (this.$route.name === 'addGroupedExpenses') {
        Promise.all([
          this.fetchProducts({
            by_active_status: true,
            meta: {
              pagination: this.productsPagination,
            },
          }),
          this.fetchGroupedExpenses({
            projectId: this.$route.params.projectId,
          }),
          this.fetchExpensesTypeCatalogs(),
        ])
          .then(response => {
            const [, , expensesTypes] = response
            this.expensesTypes = expensesTypes.data.map(expense => ({
              text: expense.name,
              value: expense.id,
            }))
            const expenseType = this.expensesTypes.find(
              expense => expense.text === 'Material',
            ).value
            this.setExpenseType(expenseType)
            // groupedExpenses.data.forEach(expense => {
            //   const foundProduct = products.data.find(
            //     product => product.sku === expense.sku,
            //   )
            //   if (foundProduct) {
            //     foundProduct.top_quantity = expense.top_quantity
            //     foundProduct.top_amount = expense.top_amount
            //     foundProduct.purchased_quantity = expense.purchased_quantity
            //     foundProduct.purchased_amount = expense.purchased_amount
            //     this.addItem(foundProduct)
            //   }
            // })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

    isIncomeAlreadyAdded(expense) {
      if (expense.name === 'Material') return false
      if (
        this.groupedExpenses.find(
          group => group.expense_type_catalog.id === expense.id,
        )
      ) return true
      if (
        this.posItems.find(
          group => group.expense_type_catalog_id === expense.id,
        )
      ) return true
      return false
    },

    add(product) {
      return
      /* eslint-disable */
      if (this.isSmallScreen === false) {
        if (this.$route.name === 'addGroupedExpenses') {
          this.addItem({
            ...product,
            expense_type_catalog_id: this.expenseType,
            isMaterial: true,
          })
        } else {
          this.addItem({ ...product })
        }
      }
      /* eslint-enable */
    },

    addGroupedExpense(expense) {
      if (expense.id === this.expenseType) {
        // open modal to add new material
        this.$bvModal.show('newMaterial')
        return
      }
      this.addItem({
        name: expense.name,
        id: expense.id,
        expense_type_catalog_id: expense.id,
      })
    },
    addNewMaterial(expense) {
      this.addItem({
        ...this.newMaterial,
        id: expense.id,
        expense_type_catalog_id: this.expenseType,
        isMaterial: true,
      })
      this.$bvModal.hide('newMaterial')
      this.newMaterial = {}
    },

    saveProducts() {
      this.posItems.forEach(item => {
        this.stockable.stockable_records.push({
          product_catalog_id: item.id,
          price_list: item.quantity,
          out_of_stock: false,
        })
      })
      if (this.$route.params.type === 'proveedor') {
        this.stockable.stockable_type = 'Supplier'
      }
      if (this.$route.params.type === 'sucursal') {
        this.stockable.stockable_type = 'SupplierBranch'
      }
      this.addStockableProduct(this.stockable).then(() => {
        this.$router.push({
          name: 'supplierProductsCatalogs',
          params: { id: this.$route.params.id, type: this.$route.params.type },
        })
      })
    },

    saveQuoteProducts() {
      const products = []
      this.posItems.forEach(item => {
        products.push({
          product_catalog_id: item.id,
          quantity: item.quantity,
          quote_id: this.$route.params.quoteId,
        })
      })
      this.quote.quote_items_attributes = products
      this.editQuote(this.quote).then(response => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('quoteSaved'),
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.$router.push({
          name: 'quotes',
          params: { projectId: response.project_id },
        })
      })
    },

    checkForAwaitingStatus(quote, tries = 5) {
      const attempts = tries
      return new Promise((resolve, reject) => {
        if (attempts <= 0) {
          reject(new Error('attempts_ran_out'))
        } else if (quote.quote_status !== 'awaiting_customer_response') {
          this.fetchQuote(quote.id).then(response => {
            if (response.quote_status === 'awaiting_customer_response') {
              resolve()
            } else {
              setTimeout(
                () => resolve(this.checkForAwaitingStatus(response, attempts - 1)),
                2500,
              )
            }
          })
        } else {
          resolve()
        }
      })
    },

    async saveAndPublish() {
      try {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('waitForBidding'),
            icon: 'ThumbsUpIcon',
            variant: 'info',
          },
        })
        this.publishLoading = true
        const products = []
        this.posItems.forEach(item => {
          products.push({
            product_catalog_id: item.id,
            quantity: item.quantity,
            quote_id: this.$route.params.quoteId,
          })
        })
        this.quote.quote_items_attributes = products
        const response = await this.editQuote(this.quote)
        await this.publishQuote(response.id)
        await this.checkForAwaitingStatus(response)
        await this.setQuoteBidding(response.id)
        this.$router.push({
          name: 'quotes',
          params: { projectId: response.project_id },
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('biddingSet'),
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        })
      } catch (e) {
        if (e.message === 'attempts_ran_out') {
          this.$router.push({
            name: 'quotes',
            params: { projectId: this.quote.project_id },
          })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('biddingErrorAtPost'),
              icon: 'ThumbsUpIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.publishLoading = false
      }
    },

    saveExpenses() {
      const expense = {
        project_id: this.$route.params.projectId,
        grouped_expense_records: [],
      }
      expense.grouped_expense_records = this.posItems.map(item => ({
        expense_type_catalog_id: item.expense_type_catalog_id,
        name: item.name,
        top_quantity: item.top_quantity || 0,
        top_amount: item.top_amount,
        unit_type: item.unit_type,
        brand: item.brand,
        description: item.description,
        sku: item.sku,
      }))
      expense.grouped_expense_records = expense.grouped_expense_records.filter(
        product => {
          if (!this.groupedExpenses.find(item => item.sku === product.sku)) {
            return true
          }
          return false
        },
      )
      this.addGroupedExpenses(expense).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('dataSaved'),
            icon: 'MinimizeIcon',
            variant: 'success',
          },
        })
        this.$router.push({
          name: 'groupedExpenses',
          params: { projectId: this.$route.params.projectId },
        })
      })
    },

    filterBy(category) {
      this.page = 1
      this.changeProductsPagination({ field: 'page', value: this.page })
      this.fetchProducts({
        by_active_status: true,
        by_category: this.selectedCategory ? category : null,
        by_name: this.searchQuery ? this.searchQuery : null,
        meta: {
          pagination: this.productsPagination,
        },
      })
    },

    setLimitSuppliersValue(value) {
      if (value) {
        this.quote.is_express = false
      }
    },
    setExpressValue(value) {
      if (value) {
        this.quote.limit_suppliers = false
      }
    },

    /* -------------------------------------------------------------------------- */
    /*                           INFINITE SCROLL SCRIPT                           */
    /* -------------------------------------------------------------------------- */

    handleScroll: _.debounce(async function (event) {
      try {
        if (
          event.target.offsetHeight + event.target.scrollTop
          >= event.target.scrollHeight - 5
        ) {
          // while its less than total products, keep adding up
          if (this.page < this.productsPagination.total_pages) {
            this.productsLoading = true
            this.page += 1
            this.changeProductsPagination({ field: 'page', value: this.page })
            const products = await axios.get('/v1/product_catalogs', {
              params: {
                by_active_status: true,
                by_category: this.selectedCategory
                  ? this.selectedCategory
                  : null,
                by_name: this.searchQuery ? this.searchQuery : null,
                meta: {
                  pagination: this.productsPagination,
                },
              },
            })
            const addedProducts = [...this.products, ...products.data.data]
            products.data.data = addedProducts
            this.setProducts(products.data)
          }
        }
      } finally {
        this.productsLoading = false
      }
    }, 200),
  },
}
</script>

<style lang="scss" scoped>
.pos {
  background-color: #ece9ef;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 8px;
  min-height: 100%;
  height: calc(100vh - 33vh);
}
.products {
  height: calc(100vh - 310px);
}
.search-input {
  min-width: 300px;
}

.notification-number {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 3px 6px;
  border-radius: 50%;
  background-color: red;
  color: white;
}

.modal-h-100 {
  background-color: red;
}

.scrollable {
  max-height: 300px !important; /* Ajusta el valor a la altura deseada */
  overflow-y: auto;
}
</style>
